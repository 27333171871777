//登录

import { deleteRequest, downPostRequest, getRequest, postRequest, putRequest } from '@/common/config/request';
import { SYS_BASE_URL_API } from "@/common/constant/Const";

export const login = (data) =>{
    return postRequest(SYS_BASE_URL_API + "/api/sys/login/login",data);
}

export const logout = (data) =>{
    return getRequest(SYS_BASE_URL_API + "/api/sys/login/logout",data);
}

//获取路由
export const getRouteList = (data) =>{
    return getRequest(SYS_BASE_URL_API + "/api/menu/tree",data);
}